<template>
     <v-container fluid>
    <header-app></header-app>
    <complejo-home :complejo=complejo :colorMenu=colorMenu :items1=items1 :items2=items2 :items3=items3  ></complejo-home>
     </v-container>

</template>
<script>
import headerApp from '../components/headerApp.vue'
import complejoHome from '../components/complejohome'
export default {
  components:{complejoHome , headerApp},
  data() {
    return {
      complejo:"rda",
      colorMenu:'#2E7D32',
      items1: [
        {
          texto1:"Nuestra",
          texto2:"Carta",
          icono:"mdi-silverware",
          destino:"cartarda"
        },
        {
          texto1: "Carta",
          texto2: "Mediodía",
          icono: "mdi-chef-hat",
          destino: "bbqrda"
        },
        {
          texto1: "Room",
          texto2: "Service",
          icono: "mdi-room-service-outline",
          destino: "roomservicerda"
        }        
      ],
      items2:[{
           texto1: "Normas del Complejo",
           icono: "mdi-book-open-page-variant",
           destino: "normasrda"
           },
           {
             texto1: "Numeros Importantes",             
           icono: "mdi-phone-classic",
           destino: "telefonosrda"
           }
      ],
      items3:[{        
           texto1: "Servicios",
           texto2: "del complejo",
           icono: "mdi-pool",
           destino: "serviciosrda"
           },
           {
             texto1: "Actividades",
             texto2: "recreativas del día" ,            
           icono: "mdi-basketball",
           destino: "actividadesrda"
           }
      ],
      miniVariant: false,
      title: "Rincon Club",
      activeBtn: null,
      activeBtn2: 0
    }
  },
  created: function() {
    document.title = "Rincon de los Andes";
  }
}
</script>
<style scoped>

</style>
